<template>
    <ion-page>
    <ion-content style="--background: var(--ion-color-light-tint)">
        <div class="container-modal" v-if="data && miniweb">
            <ion-header class="ion-no-border" id="menu-header">
            <ion-toolbar style="--background: var(--ion-color-light-tint)" class="menu-header-item">
                <h5 slot="start">Detalles</h5>
                <ion-buttons slot="end">
                    <ion-button @click="close()">
                        <ion-icon slot="icon-only" src="/assets/img/uicons/fi-rr-cross-small.svg"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            </ion-header>

            <section id="galeria" class="mb-sm">
                <swiper :pagination="true" :spaceBetween="16" @swiper="setProductSlider" class="product-swiper"  :modules="modules">
                    <!-- start slider item -->
                    <swiper-slide v-for="slide in data.images" :key="slide">
                        <AnimatedComponent animation-enter="animate__fadeIn">
                            <ion-thumbnail class="image">
                                <img :src="slide[1024]" :alt="miniweb.information.name">
                            </ion-thumbnail>
                        </AnimatedComponent>
                    </swiper-slide>
                    <!-- end slider item -->
                </swiper>

                <swiper v-if="data.images.lenght > 1" :slidesPerView="3" :spaceBetween="16" class="thumbnails-swiper" :freeMode="true" :loop="false"
                            :breakpoints="{
                                        '540': {
                                            slidesPerView: 3,
                                        },
                                        '720': {
                                            slidesPerView: 3,
                                        },
                                        '960': {
                                            slidesPerView: 4,
                                        },
                                        '1440': {
                                            slidesPerView: 4,
                                        },
                                    }">
                    <swiper-slide v-for="slide in data.images" :key="slide">
                        <AnimatedComponent animation-enter="animate__fadeIn">
                            <ion-thumbnail class="image">
                                <img class="w-100" :src="slide[1024]" :alt="miniweb.information.name" @click="goToSlide(0)" style="cursor: pointer">
                            </ion-thumbnail>
                        </AnimatedComponent>
                    </swiper-slide>
                </swiper>
            </section>

            <section id="titulo" class="mb-sm">
                <AnimatedComponent animation-enter="animate__fadeIn">
                    <h4 class="pb-xs">{{data.name}}</h4>
                    <p class="price pb-sm" v-if="data.price">{{data.price.toFixed(2)}}€</p>
                    <div class="flex">
                        <ion-button shape="round" class="whatsapp-button" href="https://api.whatsapp.com/send?phone=34601536456&text=Hola%2C%20estaba%20interesado%20en..." target="_blank">
                            <ion-icon slot="start" src="/assets/img/uicons/fi-brands-whatsapp.svg" style="margin-right: 8px; color: #fff;"></ion-icon>
                            <ion-label>Contactar</ion-label>
                        </ion-button>
                    </div>
                </AnimatedComponent>
            </section>

            <section id="descripcion">
                <AnimatedComponent animation-enter="animate__fadeIn">
                    <p class="description-paragraph" v-html="data.description"></p>
                </AnimatedComponent>
            </section>

            <div class="blank-space"></div>

        </div>
    </ion-content>
</ion-page>
</template>


<script>
import { defineComponent } from 'vue';
import { IonPage, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, modalController } from '@ionic/vue';
import AnimatedComponent from '@/components/others/AnimatedComponent.vue';

import { Pagination, Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import "swiper/css/free-mode";
import '@ionic/vue/css/ionic-swiper.css';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'DetailsComponent',
  components: { IonPage, IonContent, IonHeader,IonToolbar, IonButtons, IonButton, IonIcon, Swiper, SwiperSlide, AnimatedComponent },
  props:['data'],
  data() {
    return {
        ProductSlider: {},
    }
  },
  computed: {
    ...mapState(['miniweb'])
  },
  methods: {
    // Galería
    goToSlide(index){
        this.ProductSlider.slideTo(index, 500, true)
    },

    setProductSlider(swiper){
        this.ProductSlider = swiper;
    },

    close(){
        modalController.dismiss();
    },
    setup() {
        return { modules: [Pagination, Autoplay, FreeMode] };
    }
  },
});
</script>

<style scoped>
    .contact-menu{
        --width: 100%;
        --max-width: 800px;
        --background: var(--ion-color-light-tint);
    }

    #menu-header{
        overflow: hidden;
        background: transparent;
        border-bottom: 0;
        margin: 16px 0;
    }

    .menu-header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: visible;
        margin: 0 auto;
    }

    /* GALERIA */
    .product-swiper {
        width: 100%;
        height: auto;
    }

    .product-swiper a{
        width: 100%;
        height: auto;
    }

    .product-swiper .image{
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 100% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
        margin: 0;
        border-radius: 16px;
    }

    .product-swiper .image ion-chip{
        margin-left: 12px;
        margin-top: 12px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .product-swiper .image img{
        display: block;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        left: 0;
        right: 0;
        transition: opacity 0.5s linear;
    }

    .thumbnails-swiper {
        width: 100%;
        height: auto;
    }

    .thumbnails-swiper a{
        width: 100%;
        height: auto;
    }

    .thumbnails-swiper .image{
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 100% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
        margin-top: 1rem;
        border-radius: 8px;
    }

    .thumbnails-swiper .image img{
        display: block;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        left: 0;
        right: 0;
        transition: opacity 0.5s linear;
    }

    .whatsapp-button{
        --background: #25d366;
        --background-activated: #128c7e; 
        --background-focused: #128c7e;
        --background-hover: #128c7e;
    }

    .price{
        color: var(--ion-color-primary);
        font-size: 16px;
    }

    .blank-space{
        width: 100%;
        height: 72px;
    }

    .description-paragraph:deep(p){
        padding-bottom: 16px;
    }

    
</style>